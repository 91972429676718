<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";


/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "分類頁管理",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "分類頁管理",
          active: true,
        }
      ],
      search_data: {
        id: '',
        mainType: null,
        subType: null,
        status: 0,
        pageType: null,
        editorType: 0,
        editor: null,
        dateType: 0,
        dateStart: null,
        dateEnd: null,
      },
      currentDateRange: [],

      // 狀態選項
      statusOptions: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '已發佈'
        },
        {
          id: 2,
          name: '未發佈'
        }
      ],
      // 頁面類型選項
      pageTypeOptions: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '主分類'
        },
        {
          id: 2,
          name: '次分類'
        }
      ],
      // 人員ˇ選項
      editorTypeOptions: [
        {
          id: 0,
          name: '上稿人員'
        }
        // {
        //   id: 1,
        //   name: '審核人員'
        // },
        // {
        //   id: 2,
        //   name: '發佈人員'
        // }
      ],
      // 時間選項
      dateTypeOptions: [
        {
          id: 0,
          name: '上稿時間'
        }
        // {
        //   id: 1,
        //   name: '審核時間'
        // },
        // {
        //   id: 2,
        //   name: '發佈時間'
        // }
      ],
      // 人員清單列表
      editorOptions: [],

      visible: true,

      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "selected",
          label: "全選",
          class: 'width-auto center'
        },
        {
          key: "id",
          label: "ID",
          class: 'width-auto center'
        },
        {
          key: "main_sub_type",
          label: "分類名稱",
          class: 'width-auto center'
        },
        {
          key: "page_type",
          label: "頁面類型",
          class: 'width-auto center'
        },
        {
          key: "status",
          label: "狀態",
          class: 'width-auto center'
        },
        {
          key: "releaser",
          label: "發布人員",
          class: 'width-auto center'
        },
        {
          key: "release_time",
          label: "發布日期",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "editor",
          label: "最近編輯人",
          class: 'width-auto center'
        },
        {
          key: "last_edit_time",
          label: "最近編輯時間",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "操作",
          stickyColumn: true,
          class: 'width-auto center'
        },
      ],
      checkRowId: []
    };
  },
  computed: {
    rows () {
      return this.tableData.length;
    },
    isAllSelected () {
      return this.rows !== 0 && this.rows === this.checkRowId.length
    }
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.searchManagerList('')
    this.reloadTypePageList()
  },
  methods: {
    // 搜尋人員列表
    searchManagerList (_keyword) {
      let vm = this

      this.$root.apis.getManagersList({
        name: _keyword
      }, function (_response) {
        let items = _response.body.data.items
        items.forEach(function (_item) {
          vm.editorOptions.push(_item)
        })
      })
    },
    /**
     * Search the table data with search input
     */
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onStatusChange (_status) {
      this.search_data.status = _status
    },
    onDateSelect (_dateRange) {
      this.currentDateRange = _dateRange
      if (_dateRange.length > 0) {
        this.search_data.dateStart = this.$root.common.getNowTime('date', _dateRange[0])
        this.search_data.dateEnd = this.$root.common.getNowTime('date', _dateRange[1])
      }
    },
    searchTypePageList (_table, _callback) {
      const vm = this
      const searchParam = {
        page: this.currentPage || 1,
        id: this.search_data?.id || null,
        maintype_id: this.search_data.mainType?.id || null,
        subtype_id: this.search_data.subType?.id || null,
        status: this.search_data.status,
        page_type: this.search_data.pageType?.id || null,
        editer_id: this.search_data.editor?.id || null,
        post_start_date: this.search_data?.dateStart || null,
        post_end_date: this.search_data?.dateEnd || null
        // 目前 API 沒提供
        // editor_type: this.search_data?.editorType || null,
        // date_type: this.search_data?.dateType || null
      }
      this.$root.apis.getTypePageList(searchParam,
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            vm.tableData = response.comments
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    onResetSearch () {
      console.log('reset')
      this.search_data = {
        id: '',
        mainType: null,
        subType: null,
        status: 0,
        pageType: null,
        editorType: 0,
        editor: null,
        dateType: 0,
        dateStart: null,
        dateEnd: null,
      }
      this.currentDateRange = []
    },
    onSelectAllRowId () {
      if (this.isAllSelected) {
        this.checkRowId = []
      } else {
        this.checkRowId = this.tableData.map(_data => _data.id)
      }
    },
    onCreateTypePage () {
      const vm = this
      this.$root.apis.createNewsTypePage(
        function (_response) {
          vm.$router.push(`/types-page/setting/${_response.body.data}?create=true`)
        },
        function (_error) {
          console.error(_error)
          vm.$router.push('/types-page')
        }
      )
    },
    // 刪除單一筆分類
    onRemoveTypePage (_id = null) {
      const vm = this
      const count = _id ? '這個' : ` ${this.checkRowId.length} 個`
      this.$root.common.confirmAction("刪除分類頁", `確定要刪除${count}分類頁？`, "刪除", "再想一下", 
        function (_result) {
          if (_id) {
            vm.$root.apis.deleteNewsTypePage(_id, function (_response) {
              vm.reloadTypePageList()
            }, vm.actionError)
          } else {
            vm.onRemoveMultipleTypePage()
          }
        }
      )
    },
    async onRemoveMultipleTypePage () {
      const vm = this
      const releasePromises = this.checkRowId.map(id => {
        return new Promise((resolve, reject) => {
          vm.$root.apis.deleteNewsTypePage(id, resolve, reject);
        });
      });

      try {
        await Promise.all(releasePromises);
        this.$root.common.showSingleNotify('刪除成功')
      } catch {
        this.$root.common.showErrorDialog('有部分刪除失敗，請確認！')
      } finally {
        this.reloadTypePageList()
      }
    },
    onReleaseTypePage (_id = null) {
      const vm = this
      const count = _id ? '這個' : ` ${this.checkRowId.length} 個`
      this.$root.common.confirmAction("發佈分類頁", `確定要發佈${count}分類頁？`, "發佈", "再想一下", 
        function (_result) {
          if (_id) {
            vm.$root.apis.releaseNewsTypePage(_id, function (_response) {
              vm.reloadTypePageList()
            }, vm.actionError)
          } else {
            vm.onReleaseMultipleTypePage()
          }
        }
      )
    },
    async onReleaseMultipleTypePage () {
      const vm = this
      const releasePromises = this.checkRowId.map(id => {
        return new Promise((resolve, reject) => {
          vm.$root.apis.releaseNewsTypePage(id, resolve, reject);
        });
      });

      try {
        await Promise.all(releasePromises);
        this.$root.common.showSingleNotify('發佈成功')
      } catch {
        this.$root.common.showErrorDialog('有部分發佈失敗，請確認！')
      } finally {
        this.reloadTypePageList()
        this.checkRowId = []
      }
    },
    reloadTypePageList () {
      this.$refs.typePageTable.refresh()
      this.checkRowId = []
    },
    actionError (_error) {
      console.error('error')
      this.$root.common.showErrorDialog(_error.body.data)
    },
    onPreview (_mainType, _subType = null) {
      if (!_mainType?.id) {
        return this.$root.common.showErrorDialog('請先選擇分類並儲存')
      }
      const topicId = _subType?.id ? `${_mainType.id}/${_subType.id}` : _mainType.id
      window.open(this.$root.site_uri + '/news/topics/' + topicId)
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">

        <!-- 搜尋bar -->
        <b-card no-body class="mb-1">

          <b-card-header header-tag="header" role="tab">
            <div class="header-row">
              <div class="header-top">
                <div class="header-title">篩選查詢 <i class="fe-settings"></i></div>
                <a class="collapse-turn mr-2" @click="visible = !visible" href="javascript: void(0);">
                  <span v-if="visible">收起 <i class="fe-chevron-up"></i></span>
                  <span v-else>展開 <i class="fe-chevron-down"></i></span>
                </a>
              </div>
              <b-button variant="primary" class="action-btn width-lg" @click="reloadTypePageList">查詢</b-button>
              <b-button variant="secondary" class="action-btn width-lg" @click="onResetSearch">清除</b-button>
            </div>
          </b-card-header>

          <b-collapse id="customaccordion-1" v-model="visible" accordion="accordion" role="tabpanel">
            <b-card-body>
              <form class="form-horizontal" role="form">
                <div class="row">
                  <!-- ID -->
                  <div class="col-12 col-lg-4">
                    <div class="form-group mb-3">
                      <label>ID</label>
                      <br />
                      <input v-model="search_data.id" type="text" class="form-control" placeholder="請輸入ID編號" />
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group mb-3">
                      <label>分類名稱</label>
                      <br />
                      <div class="row">
                        <div class="col-6">
                          <multiselect key="main-type-select" v-model="search_data.mainType" :options="$root.newsTypeList"
                            placeholder="選擇主分類" track-by="id" label="name" select-label="" />
                        </div>
                        <div class="col-6">
                          <multiselect key="sub-type-select" :disabled="!search_data.mainType"
                            v-model="search_data.subType"
                            :options="(search_data.mainType && search_data.mainType.sub_types) || []" placeholder="選擇次分類"
                            track-by="id" label="name" select-label="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group mb-3">
                      <label>狀態</label>
                      <br />
                      <div class="row">
                        <b-button v-for="status in statusOptions" :key="status.id" class="btn-rounded ml-1"
                          :variant="search_data.status === status.id ? 'primary' : 'outline-primary'"
                          @click="onStatusChange(status.id)">
                          {{ status.name }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <div class="form-group mb-3">
                      <label>頁面類型</label>
                      <br />
                      <multiselect key="page-type-select" v-model="search_data.pageType" :options="pageTypeOptions"
                        placeholder="請選擇頁面類型" track-by="id" label="name" select-label="" />
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group mb-3">
                      <label>
                        <select v-model="search_data.editorType" class="form-control label-select">
                          <option v-for="type in editorTypeOptions" :key="type.id" :value="type.id">
                            {{ type.name }}
                          </option>
                        </select>
                      </label>
                      <br />
                      <multiselect key="editor-select" v-model="search_data.editor" :options="editorOptions"
                        placeholder="請輸入人員名稱" label="name" track-by="id" select-label="" @search-change="searchManagerList" />

                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group mb-3">
                      <label>
                        <select v-model="search_data.dateType" class="form-control label-select">
                          <option v-for="type in dateTypeOptions" :key="type.id" :value="type.id">
                            {{ type.name }}
                          </option>
                        </select>
                      </label>
                      <br />
                      <date-picker :value="currentDateRange" @input="onDateSelect" range append-to-body lang="en" confirm
                        format="YYYY-MM-DD" placeholder="請選擇日期區間" />
                    </div>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-collapse>

        </b-card>

        <!-- 表格 -->
        <div class="card">
          <div class="card-body">
            <div class="header-row mb-3">
              <div class="header-title">分類頁列表</div>
              <b-button variant="primary" class="action-btn width-lg" @click="onCreateTypePage">新增</b-button>
              <span class="mt-2 mt-lg-0 ml-0 ml-lg-3 mr-0 mr-lg-1">多選操作：</span>
              <b-button :disabled="checkRowId.length === 0" variant="outline-secondary" class="action-btn width-lg"
                @click="onReleaseTypePage()">
                發佈
              </b-button>
              <b-button :disabled="checkRowId.length === 0" variant="outline-secondary" class="action-btn width-lg"
                @click="onRemoveTypePage()">
                刪除
              </b-button>
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table ref="typePageTable" :items="searchTypePageList" :fields="fields" hover responsive="sm"
                :per-page="perPage" :current-page="currentPage" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <!-- Checkbox -->
                <template #head(selected)="data">
                  <b-button variant="link" @click="onSelectAllRowId">
                    {{ isAllSelected ? '取消全選' : '全選' }}
                  </b-button>
                </template>

                <template #cell(selected)="data">
                  <b-form-checkbox v-model="checkRowId" :name="data.item.id" :value="data.item.id" :id="data.item.id" />
                </template>

                <!-- ID -->
                <template #cell(id)="data">
                  <span>{{ data.item.id || '-' }}</span>
                </template>

                <!-- 主、次分類 -->
                <template #cell(main_sub_type)="data">
                  <span v-if="data.item?.main_type?.name">
                    {{ data.item?.main_type?.name }}
                  </span>
                  <span v-else>
                    -
                  </span>
                  <span v-if="data.item?.sub_type?.name">
                    / {{ data.item?.sub_type?.name }}
                  </span>
                </template>

                <!-- 頁面類型 -->
                <template #cell(page_type)="data">
                  <span v-if="data.item.page_type === 1">主分類頁</span>
                  <span v-else-if="data.item.page_type === 2">次分類頁</span>
                  <span v-else>-</span>
                </template>

                <!-- 狀態 -->
                <template #cell(status)="data">
                  <b-button v-if="data.item.status === 0" variant="info" size="sm">未發佈</b-button>
                  <b-button v-else="data.item.status === 1" variant="success" size="sm">已發佈</b-button>
                </template>

                <!-- 發佈人員 -->
                <template #cell(releaser)="data">
                  <span>{{ data.item?.releaser.length > 0 || '-' }}</span>
                </template>

                <!-- 發佈日期 -->
                <template #cell(release_time)="data">
                  <span>{{ data.item?.release_time || '-' }}</span>
                </template>

                <!-- 最近編輯人 -->
                <template #cell(editor)="data">
                  <span>{{ data.item?.editer?.name || '-' }}</span>
                </template>

                <!-- 最近編輯人 -->
                <template #cell(last_edit_time)="data">
                  <span>{{ data.item?.lastedit_time || '-' }}</span>
                </template>

                <template #cell(action)="data">
                  <div class="action-flex">
                    <b-button class="table-btn mr-2" @click="onPreview(data.item.main_type, data.item.sub_type)">
                      預覽
                    </b-button>

                    <b-button class="table-btn mr-2" :to="'/types-page/setting/' + data.item.id">
                      編輯
                    </b-button>

                    <!-- <b-button class="table-btn mr-2 delete" @click="onRemoveTypePage(data.item.id)">
                      刪除
                    </b-button> -->

                    <b-button class="table-btn" @click="onReleaseTypePage(data.item.id)">
                      {{ data.item?.status === 1 ? '發佈更新' : '發佈' }}
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div class="row mb-md-2 mt-2">
              <div class="col">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>





      </div>
    </div>

  </Layout>
</template>
<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-top
    flex-grow: 1
    display: flex
    justify-content: space-between
    align-items: center
  .header-title
    margin: 0
    flex: 1

::v-deep .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center

::v-deep .action-flex
  display: flex
  justify-content: center

::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
  &.delete
    color: #333333

.action-btn
  margin-left: 6px

@media  (max-width: 992px)
  .header-row
    flex-direction: column
    align-items: flex-start
    .header-title
      margin-bottom: 1rem
    .header-top
      width: 100%
      justify-self: space-between
      margin-bottom: 1rem
    .action-btn
      width: 100%
      margin: 0 0 6px 0
    
</style>
